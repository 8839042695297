<template>
  <v-card v-bind="$attrs" flat outlined>
    <v-row class="group-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="3" xl="2">
        <TextDescription :description="description"/>
      </v-col>
      
      <v-col cols="12" md="9" xl="10">
        <v-form ref="form" v-model="validateForm">
          <v-row no-gutters>
            <v-col
              v-if="onPageCreateCampaign"
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text">Choose an Option</span>
            </v-col>
            <v-col class="pb-10" cols="12" sm="8" md="9" v-if="onPageCreateCampaign">
              <ToggleButton
                v-model="currentDestinationOptions"
                :buttons="destinationOptions"
                @input="openCreateCallRoute"
              />
            </v-col>
            
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text">Group name</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="groupName"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-10" cols="12" sm="8" md="9">
              <FormField
                v-model="formDataGroup.name"
                class="field-container"
                :loading="loadDetail"
                :rules="rules"
              >
                <template #message="{ key, message }">
                  <portal to="groupName" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
            
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text">Timezone</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="groupTimezone"
              ></portal-target>
            </v-col>
            
            <v-col class="pb-10 d-block d-sm-flex" cols="12" sm="8" md="9">
              <AutocompleteField
                class="field-container"
                v-model="formDataGroup['timezone_id']"
                :loading="loadDetail"
                :items="timezones"
                item-text="display"
                item-value="id"
                :rules="rules"
                checkBg
              >
                <template #message="{ key, message }">
                  <portal to="groupTimezone" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </AutocompleteField>
            </v-col>
            
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="4" md="3">
              <span class="font-normal nameField--text text-right">Redistribution schedule</span>
            </v-col>
            
            <v-col class="pb-6 pb-sm-10" cols="12" sm="8" md="9">
              <ToggleButton
                v-model="redistributionSchedule"
                :buttons="overrideOptions"
              />
            </v-col>
            
            <v-expand-transition>
              <v-col
                class="pb-10 d-flex align-start flex-column"
                cols="12" sm="8" md="9" offset="0" offset-sm="4" offset-md="3"
                v-if="redistributionSchedule > 1"
              >
                <HoursOfOperationCard
                  :hoursOfOperation.sync="formDataGroup['hours_of_operation']"
                  :validateHoursOfOperation.sync="validateHoursOfOperation"
                />
              </v-col>
            </v-expand-transition>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    
    <v-row class="setting-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="9" xl="10" offset="0" offset-md="3" offset-xl="2">
        <v-row no-gutters>
          <v-col
            class="d-flex align-center justify-start justify-sm-end pr-sm-11 pt-6 pb-4 pb-sm-8"
            cols="12" sm="4" md="3"
          >
            <span class="font-large sectionTitle--text text-right">SETTINGS</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9"></v-col>
          
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text">Redistribution Cap</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="redistributionCap"
              :buttons="overrideOptions"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="redistributionCap > 1">
              <CapSettings :capSettings.sync="capacity" />
              <RevenueCapSettings :revenueCapSettings.sync="revenueCap" />
            </v-col>
          </v-expand-transition>
          
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-right">Redistribution concurrency</span>
          </v-col>
          
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="redistributionConcurrency"
              :buttons="overrideOptions"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="redistributionConcurrency > 1">
              <ConcurrencySettings
                :concurrencySettings.sync="concurrency"
              />
            </v-col>
          </v-expand-transition>
          
          <v-col
            class="d-flex align-center justify-start justify-sm-end pr-sm-11 pt-6 pb-4 pb-sm-8"
            cols="12" sm="4" md="3"
          >
            <span class="font-large sectionTitle--text text-right">ADD DESTINATION</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9"></v-col>
          
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
            :class="!!group ? 'pb-4 pb-sm-10' : 'pb-8 pb-sm-20'"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text">Select destination</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="selectDestination"
            ></portal-target>
          </v-col>
          
          <v-col
            class="d-flex flex-column flex-md-row"
            :class="!!group ? 'pb-10' : 'pb-10 pb-sm-20'"
            cols="12" sm="8" md="9"
          >
            <AutocompleteField
              class="field-container"
              v-model="changedDestinations"
              :loading="loadingAllDestination"
              :items="allDestinations"
              height="46"
              item-text="name"
              item-value="slug"
              :rules="rules"
              multiple
              return-object
              checkBg
            >
              <template #message="{ key, message }">
                <portal to="selectDestination" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
              
              <template #selection="{ item, index }">
                <span class="inputColor--text" v-if="index === 0">{{ item.name }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text caption pl-6"
                >+{{ changedDestinations.length - 1 }} others</span>
              </template>
            </AutocompleteField>
            
            <ActionOutlinedButton
              v-if="!isExpandPanel"
              class="ml-0 ml-md-11 mt-6 mt-md-0"
              @click="openDestinationPanel"
              height="46"
              max-width="200"
            >
              Create New Destination
            </ActionOutlinedButton>
          </v-col>
          
          <v-col
            class="d-flex justify-sm-end pr-sm-11 pt-4 pb-5 pb-sm-10 pb-md-0"
            cols="12"
            sm="4"
            md="3"
            v-if="!!groupSlug"
          >
            <span class="font-normal nameField--text">Attached destinations</span>
          </v-col>
          
          <v-col class="pb-20 table-container table-container" cols="12" md="9" v-if="visibleTable">
            <AddedDestinationsToGroupTable
              :items="group.targets"
              :loadingDetailGroup="loadDetail"
              :loadingStatus="loadingStatus"
              @changeStatus="changeStatus"
              @detachDestination="openDetachDestinationDialog"
              @resetCapsDestination="openResetCapsDialog"
            />
          </v-col>
          
          <v-col
            cols="12" sm="8" md="9" offset="0" offset-sm="4" offset-md="3"
            class="mt-2 mt-sm-8"
            v-if="!visibleAddDestinationPanel"
          >
            <CancelButton v-if="isExpandPanel" class="mr-8 mr-sm-10" @click="closePanel">Cancel</CancelButton>
            
            <ActionButton :loading="loading" @click="submitForm">{{ groupSlug ? 'Update' : 'Create' }}</ActionButton>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <v-slide-y-transition hide-on-leave>
      <div
        id="create-new-destination"
        class="destination-container mx-n12 mb-n15"
        :class="checkSm ? 'mx-sp-n20 mb-sp-n23' : 'mx-sm-n25 mb-sm-n25'"
        v-if="visibleAddDestinationPanel"
      >
        <CardDestination
          class="expandPanelBg pa-12 py-md-20 rounded-t-0"
          :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
          :description="descriptionPanel"
          isExpandPanel
          @closePanel="closePanelNewDestination"
          @createSuccess="addNewDestination"
        />
      </div>
    </v-slide-y-transition>
    
    <DialogConfirm
      v-model="confirmDialog"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      :loading="loadingAction"
      @actionSuccess="goToDetachDestination"
    />
    
    <DialogResetCaps
      v-model="resetCapsDialog"
      :itemId="destinationSlug"
      forWhomAction="Destination"
    />
  </v-card>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ToggleButton from '@/components/buttons/ToggleButton'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'
import HoursOfOperationCard from '@/components/pickers/HoursOfOperationCard'
import CapSettings from '@/components/destination/CapSettings'
import RevenueCapSettings from '@/components/destination/RevenueCapSettings'
import ConcurrencySettings from '@/components/destination/ConcurrencySettings'
import AddedDestinationsToGroupTable from '@/components/tables/AddedDestinationsToGroupTable'
import CardDestination from '@/components/cards/CardDestination'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogResetCaps from '@/components/dialog/DialogResetCaps'

import { mapState } from 'vuex'
import { getOverrideOptions, createGroup, updateGroup, attachDestinationsByGroup, detachDestinationsFromGroup } from '@/api/group-routes'
import { getAllDestinations, changeDestinationStatus } from '@/api/destination-routes'

export default {
  name: 'CardGroup',
  inheritAttrs: false,
  components: { TextDescription, ToggleButton, ActionOutlinedButton, CancelButton, ActionButton, FormField, AutocompleteField, HoursOfOperationCard, CapSettings, RevenueCapSettings, ConcurrencySettings, AddedDestinationsToGroupTable, CardDestination, DialogConfirm, DialogResetCaps },
  props: {
    description: {
      type: Object,
      required: true
    },
    groupSlug: {
      type: String | Number,
      default: null
    },
    group: {
      type: Object,
      default: () => ({})
    },
    loadDetail: {
      type: Boolean,
      default: false
    },
    isExpandPanel: {
      type: Boolean,
      default: false
    },
    onPageCreateCampaign: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    loading: false,
    loadingAction: false,
    loadingStatus: false,
    loadingAllDestination: false,
    validateForm: true,
    validateHoursOfOperation: true,
    visibleAddDestinationPanel: false,
    descriptionPanel: {
      title: 'ADD DESTINATION',
      text: []
    },
    overrideOptions: [],
    allDestinations: [],
    changedDestinations: [],
    destinationToDetach: null,
    rules: [],
    currentDestinationOptions: 2,
    destinationOptions: [
      { name: 'Add Existing', id: 1 },
      { name: 'Create new', id: 2 },
    ],
    redistributionSchedule: 1,
    redistributionCap: 1,
    redistributionConcurrency: 1,
    formDataGroup: {
      name: null,
      'timezone_id': null,
      'hours_of_operation': null,
    },
    capacity: {
      'global_cap': null,
      'monthly_cap': null,
      'daily_cap': null,
      'hourly_cap': null,
    },
    capacityDefault: {
      'global_cap': null,
      'monthly_cap': null,
      'daily_cap': null,
      'hourly_cap': null,
    },
    revenueCapDefault: {
      'global_revenue_cap': null,
      'monthly_revenue_cap': null,
      'daily_revenue_cap': null,
      'hourly_revenue_cap': null,
    },
    revenueCap: {
      'global_revenue_cap': null,
      'monthly_revenue_cap': null,
      'daily_revenue_cap': null,
      'hourly_revenue_cap': null,
    },
    concurrencyDefault: {
      'max_concurrency': null,
      'hourly_concurrency': null,
    },
    concurrency: {
      'max_concurrency': null,
      'hourly_concurrency': null,
    },
    confirmDialog: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    resetCapsDialog: false,
    destinationSlug: null,
  }),
  computed: {
    ...mapState({
      timezones: state => state.timezones,
    }),
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    visibleTable() {
      return !!this.groupSlug && Array.isArray(this.group.targets)
    }
  },
  mounted() {
    this.goToLoadOverrideOptions()
    this.goToLoadAllDestinations()
  },
  methods: {
    parseData(group) {
      const someCap = Object.keys(this.capacity).some(name => group[name] !== null)
      const someRevenueCap = Object.keys(this.revenueCap).some(name => group[name] !== null)
      this.redistributionCap = someCap || someRevenueCap ? 2 : 1
      this.redistributionConcurrency = Object.keys(this.concurrency).some(name => group[name]) ? 2 : 1
      this.redistributionSchedule = group['override_schedule_option_id']

      Object.keys(this.formDataGroup).forEach(name => {
        this.formDataGroup[name] = group[name]
      })
      Object.keys(this.capacity).forEach(name => {
        this.capacity[name] = group[name]
      })
      Object.keys(this.revenueCap).forEach(name => {
        this.revenueCap[name] = group[name]
      })
      Object.keys(this.concurrency).forEach(name => {
        this.concurrency[name] = group[name]
      })
    },
    async goToLoadOverrideOptions() {
      const { success, payload, message } = await getOverrideOptions()

      if (success) {
        this.overrideOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadAllDestinations() {
      this.loadingAllDestination = true

      const { success, payload, message } = await getAllDestinations()

      if (success) {
        this.allDestinations = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAllDestination = false
    },
    async submitForm() {
      this.redistributionSchedule > 1 || (this.validateHoursOfOperation = true)
      await this.validationForm()

      if (this.$refs.form.validate() && this.validateHoursOfOperation) {
        let formData = Object.assign(
          {},
          this.formDataGroup,
          { 'override_schedule': this.redistributionSchedule }
        )
        if (this.redistributionCap > 1) {
          formData = Object.assign(
            formData,
            this.onlyNumber(this.capacity),
            this.onlyNumber(this.revenueCap)
          )
        } else {
          formData = Object.assign(
            formData,
            this.onlyNumber(this.capacityDefault),
            this.onlyNumber(this.revenueCapDefault)
          )
        }

        if (this.redistributionConcurrency > 1) {
          formData = Object.assign(
            formData,
            this.onlyNumber(this.concurrency)
          )
        } else {
          formData = Object.assign(
            formData,
            this.onlyNumber(this.concurrencyDefault)
          )
        }
        
        if (this.groupSlug) {
          this.goToUpdateGroup(formData)
        } else {
          this.goToCreateGroup(formData)
        }
      }
    },
    async goToCreateGroup(formData) {
      this.loading = true

      const { success, payload, message } = await createGroup(formData)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.goToAttachDestinations(payload.slug)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async goToUpdateGroup(formData) {
      this.loading = true
      
      const { success, payload, message } = await updateGroup({
        groupId: this.groupSlug,
        formData
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('successUpdate', payload)
        this.goToAttachDestinations()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    async goToAttachDestinations(newGroupSlug = null) {
      if (!this.changedDestinations.length) {
        if (!!newGroupSlug) {
          if (this.onPageCreateCampaign) {
            this.$emit('createSuccess', newGroupSlug)
          } else {
            this.$router.push({ name: 'DetailGroup', params: { slug: newGroupSlug }})
          }
        }
        return
      }
      const addedDestination = !!newGroupSlug
        ? this.changedDestinations
        : this.changedDestinations.reduce((destinations, destination) => {
          if (this.group.targets.every(d => d.slug !== destination.slug)) {
            destinations.push(destination)
          }
          return destinations
        }, [])
      const { success, message } = await attachDestinationsByGroup({
        groupId: !!newGroupSlug ? newGroupSlug : this.groupSlug,
        attach: addedDestination.map(item => item.slug),
      })

      if (success) {
        if (!!newGroupSlug) {
          if (this.onPageCreateCampaign) {
            this.$emit('createSuccess', newGroupSlug)
          } else {
            this.$router.push({ name: 'DetailGroup', params: { slug: newGroupSlug }})
          }
          return
        }
        this.$emit('attachDestinations', addedDestination)
        this.changedDestinations = []
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    closePanelNewDestination() {
      this.visibleAddDestinationPanel = false
    },
    closePanel() {
      this.$emit('closePanel')
    },
    onlyNumber(obj) {
      Object.keys(obj).forEach(name => {
        obj[name] = obj[name] ? +obj[name] : null
      })
      return obj
    },
    async changeStatus(destination) {
      this.loadingStatus = true
      
      const { success, payload, message } = await changeDestinationStatus({
        destinationId: destination.slug,
        formData: { status: destination['status_id'] === 1 ? 2 : 1 }
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('updateStatus', payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingStatus = false
    },
    openDetachDestinationDialog(destination) {
      this.destinationToDetach = destination.slug
      this.dialogTitle = 'Detach destination'
      this.dialogSubTitle = `${destination.name}`
      this.dialogMessage = 'Are you sure you want to detach this destination?'
      this.confirmDialog = true
    },
    async goToDetachDestination() {
      this.loadingAction = true
      
      const { success, message } = await detachDestinationsFromGroup({
        groupId: this.groupSlug,
        detach: this.destinationToDetach,
      })
      
      if (success) {
        this.confirmDialog = false
        this.$notify({ type: 'success-bg', text: message ? message : 'Destination was detached!' })
        this.$emit('detachDestination', this.destinationToDetach)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    async addNewDestination(destination) {
      this.allDestinations.push(destination)
      this.changedDestinations.push(destination)
      this.closePanelNewDestination()
    },
    openResetCapsDialog(destination) {
      this.resetCapsDialog = true
      this.destinationSlug = destination.slug
    },
    openDestinationPanel() {
      this.visibleAddDestinationPanel = !this.visibleAddDestinationPanel
    },
    openCreateCallRoute() {
      this.$emit('openCreateCallRoute')
    },
  },
  watch: {
    group: {
      immediate: true,
      handler(group) {
        if (!!group && Object.keys(group).length) {
          this.parseData(group)
        }
      }
    },
    redistributionSchedule: {
      handler(value) {
        if (!value) {
          this.formDataGroup['hours_of_operation'] = null
        }
      }
    },
    visibleAddDestinationPanel: {
      handler(value) {
        if (value) {
          setTimeout(() => {
            this.$vuetify.goTo('#create-new-destination')
          })
        }
      }
    },
  }
}
</script>

<style lang="scss">
  .create-group-destination-card {
    &.expandPanelBg {
      padding: 40px 0!important;
      border: none!important;
    }
    
    &:not(.expandPanelBg):not(.no-padding) {
      border: 1px solid var(--v-cardBorder-base)!important;
    }
    
    &:not(.panelIsOpen) {
      padding-bottom: 50px;
    }
  }
</style>
