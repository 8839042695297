<template>
  <v-data-table
    :headers="headers"
    :items="items"
    item-key="slug"
    class="elevation-0 borderNone font-small tableBg borderTableXS"
    outlined
    disable-pagination
    hide-default-footer
    :custom-sort="customSort"
    :header-props="{ sortIcon: '$sortDownIcon' }"
    :options.sync="options"
    :loading="loadingDetailGroup"
    :loader-height="2"
    :mobile-breakpoint="0"
  >
    <template #item.name="{ item }">
      <span class="font-normal text-no-wrap">{{ item.name }}</span>
    </template>
    
    <template #item.destination="{ item }">
      {{ item.destination ? `+${item.destination}` : '-//-' }}
    </template>
    
    <template #item.status_id="{ item }">
      <SwitchSm
        v-model="item.status_id === 1"
        @input="changeStatus(item)"
        bgColor="switcherYellow"
        :loading="loadingStatus"
      />
    </template>
    
    <template #item.actions="{ item }">
      <TooltipAction bottom message="Reset caps">
        <template #activator="{ on }">
          <v-icon v-on="on" class="mr-6" @click="resetCapsDestination(item)">$resetIcon</v-icon>
        </template>
      </TooltipAction>
      
      <TooltipAction bottom message="Detach">
        <template #activator="{ on }">
          <v-icon v-on="on" @click="detachDestination(item)">$deleteIcon</v-icon>
        </template>
      </TooltipAction>
    </template>
    
    <template #no-data>
      <div class="d-flex">
        <span class="font-normal text-none text-left">No Destinations linked to this Group</span>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import SwitchSm from '@/components/controls/SwitchSm'
import TooltipAction from '@/components/tooltip/TooltipAction'

import { customSorting } from '@/helper/app-helper'

export default {
  name: 'AddedDestinationsToGroupTable',
  inheritAttrs: false,
  components: { SwitchSm, TooltipAction },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loadingDetailGroup: {
      type: Boolean,
      default: false
    },
    loadingStatus: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    options: {},
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Destination', value: 'destination', sortable: false },
      { text: 'Status', value: 'status_id', align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ],
  }),
  methods: {
    detachDestination(destination) {
      this.$emit('detachDestination', destination)
    },
    resetCapsDestination(destination) {
      this.$emit('resetCapsDestination', destination)
    },
    changeStatus(destination) {
      this.$emit('changeStatus', destination)
    },
    customSort(items, index, isDesc) {
      const fieldName = index[0]
      const type = this.getFieldType(fieldName)

      items.sort((a, b) => {
        if(typeof a[fieldName] !== 'undefined') {
          return customSorting(a[fieldName], b[fieldName], !!isDesc[0], type)
        }
      })
      return items
    },
    getFieldType(name) {
      if (name === 'status_id') {
        return 'status'
      }

      return 'string'
    },
  },
}
</script>

<style lang="scss">
.table-container {
  max-width: 1000px;
}
</style>
